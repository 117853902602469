<script setup>
import MainPage from './views/MainPage.vue';
import {ref} from 'vue'
const input = ref('')
const searchShow = (inp) =>{
    input.value= inp
}
</script>
<template>
    <router-view></router-view>
</template>
<style scoped lang="less">
.layout {
    background-image: url('@/assets/bg4.png');
    background-size: cover;
}
</style>