<script setup>
import { CircleClose } from '@element-plus/icons-vue'
const props = defineProps({
    show: Boolean
})
const emit = defineEmits(['close'])
const close = () => {
    emit('close')
}
const closeMask = (e) =>{
    if(e.target.className==='mask'){
        emit('close')
    }
}
</script>
<template>
    <div class="mask" @click="closeMask" v-if="show">
        <div class="main">
            <div class="close">
                <el-icon @click="close">
                    <CircleClose />
                </el-icon>
            </div>
            <div class="title">
                <slot name="title"></slot>
            </div>
            <slot name="content"></slot>
            
        </div>
    </div>
</template>
<style lang="less" scoped>
.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 60px;
    background-color: rgba(29, 28, 28, 0.349);
}

.main {
    position: relative;
    background-color: #fff;
    width: 50%;
    height: 70vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #9c9c9c77;
    box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08);

    .close {
        position: absolute;
        top: 20px;
        right: 30px;

        .el-icon {
            font-size: 20px;
            color: #ea4926;
            cursor: pointer;
        }
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0;

        &::after {
            content: '';
            width: 30px;
            height: 3px;
            background-color: #ea4926;
            margin-top: 5px;
            margin-bottom: 10px;
        }
    }
}
</style>