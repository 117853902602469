<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';
import { CircleClose } from '@element-plus/icons-vue'
import { ref } from 'vue'

const textarea = ref('')
const props = defineProps({
    show: Boolean
})
const emit = defineEmits(['close'])
const close = () => {
    emit('close')
}
</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            知识交流
        </template>
        <template #content>
            <div class="content">
                <div class="input">
                    <div class="put">
                        <div class="img">
                            <img src="@/assets/user-img.png">
                        </div>
                        <div class="in">
                            <el-input v-model="textarea" :rows="3" type="textarea" placeholder="欢迎讨论" />
                        </div>
                    </div>
                    <div class="btn">
                        <div>发布</div>
                    </div>
                </div>
                <div class="talk-list" v-for="(item, index) in 10" :key="index">
                    <div class="talk-item">
                        <div class="info">
                            <div class="img">
                                <img src="@/assets/user-img.png">
                            </div>
                            <div class="user-name">dfsghda</div>
                            <div class="time">2023-12-9 12:09:42</div>
                        </div>
                        <div class="con">
                            至此，我们已经了解了如何使用字符串作为注入名。但如果你正在构建大型的应用程序，包含非常多的依赖供给，或者你正在编写提供给其他开发者使用的组件库，建议最好使用 Symbol
                            来作为注入名以避免潜在的冲突。
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style lang="less" scoped>
.content {
    overflow-y: scroll;
    height: 80%;

    &::-webkit-scrollbar {
        display: none;
    }

    .input {
        margin-bottom: 30px;

        .put {
            display: flex;
            align-items: flex-start;

            .img {
                width: 35px;
                margin-right: 10px;
            }

            .in {
                flex: 1;

                ::v-deep .el-textarea {
                    border: #ea4926;
                }
            }
        }

        .btn {
            display: flex;
            width: 100%;

            div {
                padding: 3px 15px;
                color: #fff;
                background-color: #ea4926;
                border-radius: 20px;
                margin-left: auto;
                margin-top: 10px;
                cursor: pointer;
            }
        }

    }

    .talk-list {
        .talk-item {
            margin-bottom: 20px;

            .info {
                display: flex;
                align-items: center;

                .img {
                    width: 35px;
                    margin-right: 10px;
                }

                .user-name {
                    font-size: 14px;
                }

                .time {
                    margin-left: auto;
                    color: #999;
                }
            }

            .con {
                font-size: 15px;
                margin-left: 45px;
            }
        }
    }
}
</style>