<script setup>
import { Back, Right,ArrowRight } from '@element-plus/icons-vue';
import {ref,onMounted} from 'vue'
import TalkDrawer from "@/components/TalkDrawer.vue";
import {getImportentServer} from '@/api/import.js'
const props = defineProps({
    show:Boolean
})

const ids = ref(0)
const datas = ref(null)
const emit = defineEmits('toPage','close')
onMounted(()=>{
    getList(ids.value)
})

const getList = async (id) =>{
    const {data} = await getImportentServer(id)
    if(data.list.length<=0){
        ids.value++
        getList(ids.value)
    }else{
        datas.value = data
    }
}


const prev = () => {
    getList(datas.value.prev)
}
const next = () =>{
    getList(datas.value.next)
}

const toPage = (page) =>{
    emit('toPage',page)
    // 关闭当前弹窗
    emit('close')
}

const talkShow = ref(false)
const isLike = ref(false)
const isStar = ref(false)

const showTalk = () =>{
    talkShow.value = true
    console.log(talkShow.value)
}
const closeTalk = () =>{
    talkShow.value = false
}
const changeLike = () =>{
    isLike.value = !isLike.value
}
const changeStar = () =>{
    isStar.value = !isStar.value
}
</script>

<template>
    <div class="box1" v-if="show">
        <div class="con">
            <div class="title" v-if="datas.section">{{ datas.section }}</div>
            <div class="content">
                <p v-for="(item,index) in datas.list" :key="index">{{ item.sentence }}</p>
            </div>
            <div class="end">
                    <div class="zhang" v-if="datas.chapter">-- {{ datas.chapter }} --</div>
                    <div class="to" @click="toPage(datas.pdfpage)">
                        返回原文<el-icon>
                            <ArrowRight />
                        </el-icon>
                    </div>

                </div>
        </div>

        <!-- <div class="fun">
            <div class="fun-item" @click="showTalk">
                <i class="iconfont icon-xinxi"></i>
            </div>
            <div class="fun-item">
                <i class="iconfont icon-share"></i>
            </div>
            <div class="fun-item" @click="changeStar">
                <i v-if="!isStar" class="iconfont icon-shoucang"></i>
                <i v-else class="iconfont icon-shoucangxuanzhong active"></i>
            </div>
            <div class="fun-item" @click="changeLike">
                <i v-if="!isLike" class="iconfont icon-dianzan"></i>
                <i v-else class="iconfont icon-dianzan_kuai active"></i>
            </div>
        </div> -->

        <div class="prev" @click="prev">
            <el-icon>
                <Back />
            </el-icon>
        </div>
        <div class="next" @click="next">
            <el-icon>
                <Right />
            </el-icon>
        </div>

        <!-- 知识交流弹窗 -->
    <TalkDrawer :show="talkShow" @close="closeTalk"></TalkDrawer>
    </div>
</template>
<style scoped lang="less">
.box1 {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 60;
    background-image: url('@/assets/bg4.png');
    background-size: cover;

    .fun {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 22%;
        top: 50%;
        transform: translateY(-50%);

        .fun-item {
            margin: 10px 0;
            width: 30px;
            height: 30px;
            i {
                font-size: 25px;
                &:hover{
                    color: #ea4926;
                }
            }
            .active{
                color: #ea4926;
            }
        }
    }

    .prev {
        left: 5%;
    }

    .next {
        right: 5%;
    }

    .prev,
    .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(143, 145, 144, 0.226);
        border-radius: 10px;

        .el-icon {
            font-size: 50px;
            padding: 10px;
            color: #666;
        }

        &:hover {
            background-color: #ea4a261f;
        }

        &:hover .el-icon {
            color: #ea4926;
        }
    }

    .con {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        width: 50%;
        height: 70vh;
        padding: 20px;
        transform: translate(-50%,-50%);
        border: 1px solid #9c9c9c77;
        overflow: hidden;
        box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08);

        .end {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .zhang{
                    font-size: 14px;
                    color: #666;
                }
                .to {
                    position: absolute;
                    right: 0;

                    cursor: pointer;
                    margin-left: auto;
                    color: #ea4926;
                    display: flex;
                    align-items: center;
                    margin-right: 20px;

                    .el-icon {
                        margin-left: 5px;
                    }
                }

            }


        .title {
            font-size: 20px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin:10px 0 20px;
            border-bottom: 1px dashed #66666541;

            &::after {
                content: '';
                width: 40px;
                height: 3px;
                background-color: #ea4926;
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }

        .content{
            height: 50vh;
            padding-bottom: 20px;
            overflow-y: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
            p{
                font-size: 15px;
            line-height: 2;
            text-indent: 2em;
            margin-bottom: 10px;
            }
        }

      
    }
}
</style>