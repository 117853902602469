<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';
const props = defineProps({
    show: Boolean
})
const emit = defineEmits(['close'])
const close = () => {
    emit('close')
}



</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            知识图谱
        </template>
        <template #content>
            <div class="content">
                1111
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style lang="less" scoped>
.content{
    overflow-y: scroll;
    height: 80%;
    &::-webkit-scrollbar {
            display: none;
        }
}
::v-deep .el-tree {
    font-size: 16px;
    color: #666;
}

::v-deep .el-tree-node__content {
    height: 40px;
}

.el-tree>::v-deep .el-tree-node {
    margin-bottom: 20px;
}

::v-deep .el-tree-node__expand-icon {
    color: #ea4926;
}

.el-tree--highlight-current ::v-deep .el-tree-node.is-current>.el-tree-node__content {
    background-color: #ea4a261a;
}
.final{
    background-color: #ea4a261a;
    padding: 0 5px;
    color: #ea4926;
    font-size: 14px;
}
</style>