<script setup>
import {
    DArrowLeft,
    DArrowRight,
    ArrowLeft,
    ArrowRight,
} from '@element-plus/icons-vue'
import { ref, watch } from 'vue';

const iconLeftList = ref([
    {
        name: '目录',
        icon: 'icon-mulu'
    },
    {
        name: '快速预览',
        icon: 'icon-preview'
    },
	    {
        name: '探索知识',
        icon: 'icon-sousuo'
    },
    {
        name: '知识图谱',
        icon: 'icon-zhishitupu1'
     },
     {
         name: '知识扩展',
         icon: 'icon-branch'
     },
     {
         name: '听书',
         icon: 'icon-touting'
     },
     {
         name: '注释',
         icon: 'icon-beizhu'
     },
     {
         name: '知识交流',
         icon: 'icon-xinxi'
     },
     {
         name: '点赞',
         icon: 'icon-dianzan'
     },
     {
         name: '收藏',
         icon: 'icon-shoucang'
     }
])

const iconRightList = ref([
    {
        name: '自动翻页',
        icon: 'icon-xunhuanbofang'
    },
     {
         name: '图书放大',
         icon: 'icon-fangda1'
     },
     {
         name: '图书缩小',
         icon: 'icon-suoxiao'
     },
     {
         name: '背景音乐',
         icon: 'icon-shengyin'
     },
     {
         name: '设置',
         icon: 'icon-shezhi'
     },
     {
         name: '分享',
         icon: 'icon-share'
     },
     {
         name: '反馈',
         icon: 'icon-yijianfankui'
     },
     {
         name: '帮助',
         icon: 'icon-bangzhuzhongxin'
     },
    {
        name: '关于',
        icon: 'icon-guanyu'
    },
    {
        name: '全屏',
        icon: 'icon-gl-fullScreenEnter'
    }
])

const props = defineProps({
    page: Number,
    pages: Number,
    isFull: Boolean,
    isLoop: Boolean,
    isSong: Boolean
})

const emit = defineEmits([
    'toPrev', 'toNext', 'toPage',
    'directoryShow', 'serKnowShow', 'starShow',
    'likeShow', 'talkShow', 'extendShow',
    'atlasShow', 'previewShow', 'helpShow',
    'showFull', 'feedbackShow', 'aboutShow',
    'settingShow', 'loopShow', 'BigShow',
    'SmallShow', 'SongShow', 'noteShow',
    'listenShow'
])

watch(() => props.isFull, () => {
    if (props.isFull) {
        iconRightList.value[9] = {
            name: '退出全屏',
            icon: 'icon-tuichuquanping'
        }
    } else {
        iconRightList.value[10] = {
            name: '全屏',
            icon: 'icon-gl-fullScreenEnter'
        }
    }
})

watch(() => props.isLoop, () => {
    if (props.isLoop) {
        iconRightList.value[0] = {
            name: '手动翻页',
            icon: 'icon-caozuo-shoudong'
        }
    } else {
        iconRightList.value[0] = {
            name: '自动翻页',
            icon: 'icon-xunhuanbofang'
        }
    }
})

watch(() => props.isSong, () => {
    if (props.isSong) {
        iconRightList.value[3] = {
            name: '关闭音乐',
            icon: 'icon-jingyin'
        }
    } else {
        iconRightList.value[3] = {
            name: '背景音乐',
            icon: 'icon-shengyin'
        }
    }
})

// 左边按钮
const directoryShow = () => {
    emit('directoryShow')
}
const searchShow = () => {
    emit('serKnowShow')
}
const starShow = () => {
    emit('starShow')
}
const likeShow = () => {
    emit('likeShow')
}
const talkShow = () => {
    emit('talkShow')
}
const extendShow = () => {
    emit('extendShow')
}
const atlasShow = () => {
    emit('atlasShow')
}
const previewShow = () => {
    emit('previewShow')
}
const noteShow = () => {
    emit('noteShow')
}
const listenShow = () => {
    emit('listenShow')
}

// 中间按钮
const toPrev = () => {
    emit('toPrev')
}
const toNext = () => {
    emit('toNext')
}
const toPage = (num) => {
    emit('toPage', num)
}

// 右边按钮
const showFull = () => {
    emit('showFull')
}
const feedbackShow = () => {
    emit('feedbackShow')
}
const aboutShow = () => {
    emit('aboutShow')
}
const helpShow = () => {
    emit('helpShow')
}
const settingShow = () => {
    emit('settingShow')
}
const loopShow = () => {
    emit('loopShow')
}
const BigShow = () => {
    emit('BigShow')
}
const SmallShow = () => {
    emit('SmallShow')
}
const SongShow = () => {
    emit('SongShow')
}


// 点击左侧按钮
const clickLeft = (index) => {
     switch(index){
         case 0:{
             directoryShow()
             break
         }
         case 1:{
             previewShow()
             break
         }
         case 2:{
             searchShow()
             break
         }
         case 3:{
             atlasShow()
             break
         }
         case 4:{
             extendShow()
             break
         }
         case 5:{
             listenShow()
             break
         }
         case 6:{
             noteShow()
             break
         }
         case 7:{
             talkShow()
             break
         }
         case 8:{
             likeShow()
             break
         }
         case 9 :{
             starShow()
             break
         }
         default:{
             break
         }
     }
}
// 点击右侧按钮
const clickRight = (index) => {
     switch(index){
         case 0:{
             loopShow()
             break
         }
         case 1:{
             BigShow()
             break
         }
         case 2:{
             SmallShow()
             break
         }
         case 3:{
             SongShow()
             break
         }
         case 4:{
             settingShow()
             break
         }
         case 5:{

             break
         }
         case 6:{
             feedbackShow()
             break
         }
         case 7:{
             helpShow()
             break
         }
         case 8:{
             aboutShow()
             break
         }
         case 9 :{
             showFull()
             break
         }
         default:{
             break
         }
     }
}

const inputChange = (e) =>{
    console.log(e.target.value)
    emit('toPage', e.target.value)
}
</script>
<template>
    <div class="footer">
        <div class="left-icon to-icon">
            <div class="icon-item" v-for="(item, index) in iconLeftList" :key="index" @click="clickLeft(index)">
                <el-tooltip class="box-item" effect="light" :content="item.name" placement="top">
                    <i class="iconfont" :class="item.icon"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="center-icon">
            <el-icon @click="toPage(1)">
                <DArrowLeft />
            </el-icon>
            <el-icon @click="toPrev">
                <ArrowLeft />
            </el-icon>
            <div class="page-num">
                <input type="number"  min="1" :max="pages" @change="inputChange" :value="page"/>
                 /<span> {{ pages }}</span></div>
            <el-icon @click="toNext">
                <ArrowRight />
            </el-icon>
            <el-icon @click="toPage(pages)">
                <DArrowRight />
            </el-icon>
        </div>
        <div class="right-icon to-icon">
            <div class="icon-item" v-for="(item, index) in iconRightList" :key="index" @click="clickRight(index)">
                <el-tooltip class="box-item" effect="light" :content="item.name" placement="top">
                    <i class="iconfont" :class="item.icon"></i>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>
<style scoped>
.footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    position: fixed;
    z-index: 20;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(143, 145, 144, 0.527);
}

.center-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.center-icon input{
    width: 40px;
    color: #ea4926;
    text-align: center;
    background-color: transparent;
}

.center-icon .el-icon {
    font-size: 30px;
    margin: 0 10px;
    padding: 5px;
    border-radius: 5px;
}

.center-icon .el-icon:hover {
    background-color: #ea4926;
    color: #fff;
}

.center-icon .page-num {
    flex-shrink: 0;
    border: 1px solid #666;
    padding: 2px 5px;
    font-size: 14px;
    margin: 0 10px;
    border-radius: 5px;
}

.center-icon .page-num span {
    color: #333;
    margin-left: 20px;
}

.to-icon {
    display: flex;
}

.to-icon .iconfont {
    font-size: 25px;
    color: #444;
    margin: 0 10px;
}

.to-icon .iconfont:hover {
    color: #ea4926;
}
</style>