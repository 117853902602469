<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';
import { ref } from 'vue'

const textarea = ref('')

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}
const props = defineProps({
    show: Boolean
})

</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            反馈
        </template>
        <template #content>
            <div class="content">
                <el-input v-model="textarea" :rows="15" type="textarea" placeholder="您有任何问题，都可以向我们反馈" />
                <div class="btn">
                <div>确定</div>
            </div>
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style scoped lang="less">
.content {
    .btn {
        display: flex;
        width: 100%;

        div {
            padding: 3px 15px;
            color: #fff;
            background-color: #ea4926;
            border-radius: 20px;
            margin: 30px auto;
            cursor: pointer;
        }
    }
}
</style>