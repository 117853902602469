<script setup>
import { searchServer } from '@/api/search.js'
import { DArrowLeft, Search, ArrowRight } from '@element-plus/icons-vue'
import { ref, onMounted, watch } from 'vue';

const total = ref(-1)
const dataList = ref([])
const load = ref(false)
const props = defineProps({
    show: Boolean,
    value: String
})
const emit = defineEmits(['close', 'toPage'])
const close = () => {
    total.value = -1
    sen.value = ''
    emit('close')
}
const sen = ref('')

const search = async () => {
    if (sen.value.trim()) {
        getDataList(sen.value)
    }
}

const getDataList = async (str, page) => {
    console.log(str+page)
    load.value = true
    const { data } = await searchServer(str, page)
    load.value = false
    total.value = data.total
    if (data.total > 0) {
        dataList.value = data.list
    }
}



const handleCurrentChange = (number) => {
    if(sen.value.trim()){
        getDataList(sen.value, number)
    }else{
        return
    }
   
}

const toPage = (item) => {
    console.log(item.pdfpage)
    // 跳转原文（打开这个注释就可以了）
    emit('toPage',item.pdfpage)
    // 固定跳转到100页（删除下面这行代码）
    // emit('toPage', 100)
}

</script>
<template>
    <div class="drawer" :class="{ 'drawer-open': show }">
        <div class="top1"></div>
        <div class="content">
            <div class="aside-nav">
                <div class="top">
                    <div></div>
                    <span class="name">探索知识</span>
                    <div>
                    </div>
                </div>
                <div class="search">
                    <input type="text" v-model="sen" placeholder="关键词">
                    <el-button @click="search" type="info" plain :icon="Search"></el-button>
                </div>
                <div v-if="!load && total > 0">
                    <div class="sen-list">
                        <div class="sen-item" @click="toPage(item)" v-for="(item, index) in dataList" :key="index">
                            <div class="con">{{ item.sentence }}</div>
                        </div>
                    </div>
                </div>
                <div class="loading" v-if="load">正在加载...</div>
                <div class="null" v-if="total === 0">
                    <div class="img">
                        <img src="../assets/null.png">
                    </div>
                    <div class="tex">没有找到相关内容</div>
                </div>

                <el-pagination :size="10" hide-on-single-page small background layout="prev, pager, next" :total="total"
                    @current-change="handleCurrentChange" />
            </div>
        </div>
        <div class="bottom"></div>
        <div class="close" @click="close">
            <el-icon>
                <DArrowLeft />
            </el-icon>
        </div>
    </div>
</template>
<style scoped lang="less">
.drawer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 25%;
    max-width: 400px;
    height: 100%;
    top: 0;
    left: -100%;
    margin-bottom: 50px;
    transition: left 0.5s linear;

    .top1 {
        width: 100%;
        height: 60px;
        background-color: #00000000;
    }

    .bottom {
        width: 100%;
        height: 50px;
        background-color: #00000000;
    }

    .content {
        width: 100%;
        background-color: #fff;
        padding: 20px;
        flex: 1;
        box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, .08), 0px 12px 32px rgba(0, 0, 0, .12), 0px 8px 16px -8px rgba(0, 0, 0, .16);
        ;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .close {
        position: absolute;
        top: 45%;
        left: 100%;
        padding: 30px 5px;
        background-color: rgba(143, 145, 144, 0.226);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        &:hover .el-icon {
            color: #ea4926;
        }

        &:hover {
            background-color: #ea4a261c;
        }

        .el-icon {
            font-size: 20px;
            color: #666;
        }
    }
}

.drawer-open {
    left: 0;
}

.aside-nav {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;

    .el-pagination {
        position: absolute;
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);
    }



    .loading {
        color: #999;
        margin-top: 10%;
        text-align: center;
    }

    .null {
        width: 50%;
        margin: 10% auto;

        .tex {
            margin-top: 20px;
            color: #999;
            text-align: center;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .menu {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .menu-item {
            background-color: rgba(143, 145, 144, 0.226);
            font-size: 14px;
            padding: 4px 5px;
            width: 50px;
            text-align: center;
            cursor: pointer;

            &.active {
                background-color: #ea4a265d;
                color: #ea4926;
            }
        }
    }


    .search {
        border-radius: 3px;
        position: relative;
        width: 100%;

        input {
            width: 100%;
            height: 34px;
            padding: 1px 5px;
            background-color: #00000000;
            border: 1px solid #9999999d;
            border-radius: 5px;
        }

        .el-button {
            position: absolute;
            right: 1px;
            top: 1px;
        }

    }

    .sen-list {
        margin: 15px 0;
        margin-bottom: 50px;
        height: 63vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .sen-item {
            margin-bottom: 15px;
            background-color: #ea4a261c;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;

            &:hover .con {
                color: #ea4926;
            }

            .title {
                font-size: 15px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                .el-icon {
                    color: #ea4926;
                    font-size: 15px;
                    cursor: pointer;
                }
            }

            .con {
                font-size: 14px;
            }

        }
    }

    .ci-list {
        margin: 15px 0;

        .ci-item {
            padding: 10px;

            &:not(:last-child) {
                border-bottom: 1px dashed #99999994;
            }

            .title {
                font-size: 15px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                .el-icon {
                    color: #ea4926;
                    font-size: 15px;
                    cursor: pointer;
                }
            }

            .it-list {
                .it {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #ea4a261c;
                    }

                    span {
                        background: rgba(230, 0, 0, 0.089);
                        color: #ea4926;
                        padding: 3px 10px;
                        border-radius: 10px;
                        font-size: 13px;
                    }
                }
            }
        }
    }



    .top {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #e4e3e3;
        margin-bottom: 20px;

        .name {
            display: block;
            margin-right: 10px;
            font-size: 20px;
            text-align: center;
            font-weight: bold;
            color: #ea4926;
        }

        .el-icon {
            font-size: 22px;
            color: #ea4926;
        }
    }

}
</style>