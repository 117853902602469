<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';
import { CircleClose, ArrowRight } from '@element-plus/icons-vue'
const props = defineProps({
    show: Boolean
})
const emit = defineEmits(['close'])
const close = () => {
    emit('close')
}
</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            收藏
        </template>
        <template #content>
            <div class="content">
                <div class="sen-list">
                    <div class="sen-item">
                        <div class="name">
                            <span>大学</span>
                            <el-icon>
                                <ArrowRight />
                            </el-icon>
                        </div>
                        <div class="con">诸君为大学学生，地位甚高，肩此重任，责无旁贷，故诸君不惟思所以感已，</div>
                    </div>
                    <div class="sen-item">
                        <div class="name">
                            <span>大学</span>
                            <el-icon>
                                <ArrowRight />
                            </el-icon>
                        </div>
                        <div class="con">诸君为大学学生，地位甚高，肩此重任，责无旁贷，故诸君不惟思所以感已，</div>
                    </div>
                </div>
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style lang="less" scoped>
.content {
    .sen-list {
        margin: 15px 0;

        .sen-item {
            margin-bottom: 15px;
            background-color: #ea4a261c;
            padding: 10px;
            border-radius: 10px;

            &:hover .con {
                color: #ea4926;
            }

            .name {
                font-size: 15px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                .el-icon {
                    color: #ea4926;
                    font-size: 15px;
                    cursor: pointer;
                }
            }

            .con {
                font-size: 14px;
            }

        }
    }
}
</style>