<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';
import {ref} from 'vue'

const emit = defineEmits(['close'])

const playType = ref('1')

const close = () => {
    emit('close')
}
const props = defineProps({
    show: Boolean
})

</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            设置
        </template>
        <template #content>
            <div class="content">
                <div class="set-item">
                    <div class="set-title">背景音乐</div>
                    <div class="play-set">
                        <span>播放设置：</span>
                        <el-radio-group
                         v-model="playType" class="ml-4">
                            <el-radio label="1" size="large">列表循环</el-radio>
                            <el-radio label="2" size="large">随机播放</el-radio>
                            <el-radio label="3" size="large">单曲循环</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style scoped lang="less">
.content {
    display: flex;
    height: 70%;

    .set-item {
        .set-title {
            font-size: 16px;
            display: flex;
            align-items: center;
            font-weight: bold;

            &::before {
                content: '';
                width: 5px;
                height: 15px;
                border-radius: 3px;
                background-color: #ea4926;
                margin-right: 10px;
            }
        }

        .play-set {
            display: flex;
            margin-left: 15px;
            margin-top: 15px;
            align-items: center;
            span {
                font-size: 14px;
                margin-right: 20px;
            }
        }
    }
}</style>