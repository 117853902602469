<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';
import { CirclePlusFilled } from '@element-plus/icons-vue'
import { ref } from 'vue'

const time = ref([
    {
        num: 20,
        isSelect: false
    },
    {
        num: 30,
        isSelect: false
    },
    {
        num: 45,
        isSelect: false
    },
    {
        num: 65,
        isSelect: false
    },
    {
        num: 80,
        isSelect: false
    },
    {
        num: 90,
        isSelect: false
    }
])

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}
const props = defineProps({
    show: Boolean
})

const selNum = ref(60)

const select = (index) => {
    time.value.forEach(item => {
        item.isSelect = false
    })
    time.value[index].isSelect = true
    selNum.value = time.value[index].num

}

const myTime = (index) => {
    selNum.value = index*5
}

const yes = () => {
    emit('close', selNum)
}

</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            自动翻页设置
        </template>
        <template #content>
            <div class="content">
                <div class="name">
                    <span>请选择翻页时间(s) </span>
                </div>
                <div class="time-list">
                    <div class="time-item" :class="{ 'active': item.isSelect }" @click="select(index)"
                        v-for="(item, index) in time" :key="index">{{ item.num }}</div>
                    <el-popover placement="bottom" :width="50" trigger="click">
                        <template #reference>
                            <div class="add">
                                <el-icon>
                                    <CirclePlusFilled />
                                </el-icon>
                            </div>
                        </template>
                        <div class="num-list">
                            <div class="num" @click="myTime(index)" :class="{'active':selNum===index*5}" v-for="index in 20" :key="index">
                                {{ index*5 }}
                            </div>
                        </div>

                    </el-popover>

                </div>
                <div class="sel">
                    {{ selNum }}<span>s</span>
                </div>
                <div>默认60s切换一次</div>
                <div class="btn" @click="yes">
                    <div>确认</div>
                </div>
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style scoped lang="less">
.num-list {
    max-height: 25vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .num{
        padding: 4px;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        &:hover{
            color: #ea4926;
        }
        .active{
            color: #ea4926;
            font-weight: bold;
        }
    }
    .active{
        color: #ea4926;
        font-weight: bold;
    }

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;

    .name {
        font-size: 15px;
        margin-bottom: 30px;
        display: flex;
    }

    .my {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 30px;

        span {
            font-size: 12px;
            background-color: #ea4a262d;
            padding: 3px 5px;
            border-radius: 20px;
            color: rgb(106, 107, 107);
            margin-right: 5px;
        }
    }

    .sel {
        font-size: 30px;
        font-weight: bold;
    }

    .time-list {
        display: flex;
        margin-bottom: 30px;

        .add {
            color: #ea4926;

            .el-icon {
                cursor: pointer;
                font-size: 36px;
                margin-left: 10px;
            }
        }


        .time-item {
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            margin: 0 10px;
            background-color: rgba(143, 145, 144, 0.226);
            border-radius: 50%;
            color: #666;
            cursor: pointer;

            &.active {
                background-color: #ea4926;
                color: #fff;
            }

            &:hover {
                background-color: #ea4926;
                color: #fff;
            }
        }
    }

    .btn {
        margin-top: 50px;

        div {
            padding: 3px 15px;
            color: #fff;
            background-color: #ea4926;
            border-radius: 20px;
            margin-top: 10px;
            cursor: pointer;
        }
    }
}
</style>