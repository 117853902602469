<script setup>
import { useFullscreen } from '@vueuse/core'
import { ref, onMounted, watch } from 'vue'
import { Back, Right, ArrowRight, Picture, Guide, DataLine } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { getNotionService, getNewServer, getSceneServer } from '@/api/menu.js'

import AbstractPage from './AbstractPage.vue'
import HeaderView from './HeaderView.vue'
import SentencePage from './SentencePage.vue'
import FooterView from "@/views/FooterView.vue"
import DirectoryDrawer from "@/components/DirectoryDrawer.vue"
import SerKnowDrawer from "@/components/SerKnowDrawer.vue"
import StarDrawer from "@/components/StarDrawer.vue"
import LikeDrawer from "@/components/LikeDrawer.vue"
import TalkDrawer from "@/components/TalkDrawer.vue"
import ExtendDrawer from "@/components/ExtendDrawer.vue"
import AtlasDrawer from "@/components/AtlasDrawer.vue"
import PreviewDrawer from "@/components/PreviewDrawer.vue"
import NoteDrawer from '@/components/NoteDrawer.vue'
import ListenDrawer from '@/components/ListenDrawer.vue'

import FeedbackDrawer from '@/components/FeedbackDrawer.vue'
import AboutDrawer from '@/components/AboutDrawer.vue'
import HelpDrawer from '@/components/HelpDrawer.vue'
import SettingDrawer from '@/components/SettingDrawer.vue'
import LoopDrawer from '@/components/LoopDrawer.vue'

import MenuBox from '@/components/MenuBox.vue';

import $ from 'jquery'
import '@/utils/turn.js'
import * as pdfjs from 'pdfjs-dist'
import url_01 from '@/assets/book1.pdf'


const isLoop = ref(false)
const isSong = ref(false)
const menuShow = ref(false)
const searchValue = ref('')

const abstractShow = ref(false)
const sentenceShow = ref(false)
const menuActive = ref('option1')

const drawerList = ref([
  // 左边弹窗
  {  // 目录
    name: 'directoryShow',
    isShow: false
  },
  {  // 快速预览
    name: 'previewShow',
    isShow: false
  },
  {  // 知识搜索
    name: 'serKnowShow',
    isShow: false
  },
  {  // 知识图谱
    name: 'atlasShow',
    isShow: false
  },
  {  // 知识扩展
    name: 'extendShow',
    isShow: false
  },
  {  // 听书
    name: 'listenShow',
    isShow: false
  },
  {  // 注释
    name: 'noteShow',
    isShow: false
  },
  {  // 知识交流
    name: 'talkShow',
    isShow: false
  },
  {  // 点赞
    name: 'likeShow',
    isShow: false
  },
  {  // 收藏
    name: 'starShow',
    isShow: false
  },
  // 右边弹窗
  { // 自动翻页
    name: 'loopShow',
    isShow: false
  },
  { // 设置
    name: 'settingShow',
    isShow: false
  },
  { // 分享
    name: '',
    isShow: false
  },
  { // 反馈
    name: 'feedbackShow',
    isShow: false
  },
  { // 帮助
    name: 'helpShow',
    isShow: false
  },
  { // 关于
    name: 'aboutShow',
    isShow: false
  }
])

// 总页数
const pageCav = ref(1)
// 当前页
const currentPage = ref(1)
// 页面高度、宽度
const pageHeight = ref(0)
const pageWidth = ref(0)
// 放大倍数
const scaleValue = ref(1.16)

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

const pdfInit = async (url) => {
  // 获取元素
  const pdfContainer = document.querySelector('#flipbook')
  if (!pdfContainer) {
    return
  }

  const loadingTask = pdfjs.getDocument({
    url: url,
  })
  const pdf = await loadingTask.promise
  pageCav.value = pdf._pdfInfo.numPages
  const container = document.querySelector('#flipbook')

  for (let index = 0; index < pdf.numPages; index++) {

    const page = await pdf.getPage(index + 1)
    const viewport = page.getViewport({ scale: scaleValue.value })

    const divPage = document.createElement('div')
    divPage.classList.add('page')
    const divPageContent = document.createElement('div')
    divPageContent.style.width = viewport.width;
    divPageContent.style.height = viewport.height;
    divPageContent.classList.add('canvasWrapper')
    const canvas = document.createElement('canvas')

    canvas.width = viewport.width
    canvas.height = viewport.height
    pageHeight.value = viewport.height
    pageWidth.value = viewport.width

    const context = canvas.getContext('2d')
    if (!context) {
      return
    }
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    }

    const textlayer = document.createElement("div");
    textlayer.className = "textLayer";
    textlayer.style.width = viewport.width
    textlayer.style.height = viewport.height
    page.getTextContent().then(function (textContent) {
      if (textContent) {
        pdfjs.renderTextLayer({
          textContentSource: textContent,
          container: textlayer,
          viewport: viewport,
          textDivs: [],
        });
      }
    });

    await page.render(renderContext).promise
    divPageContent.appendChild(canvas)
    divPage.appendChild(divPageContent)
    divPage.appendChild(textlayer);
    container.appendChild(divPage)
  }
}

onMounted(async () => {
  $('#flipbook').hide()
  $('.loading').show()
  await pdfInit(url_01)
  $('.loading').hide()
  $('#flipbook').show()
  onTurn()
  if (currentPage.value === 1) {
    $('#flipbook').css('marginLeft', -pageWidth.value)
  } else {
    $('#flipbook').css('marginLeft', 0)
  }
})

watch(() => currentPage.value, () => {
  if (currentPage.value === 1) {
    $('#flipbook').css('marginLeft', -pageWidth.value)
  } else {
    $('#flipbook').css('marginLeft', 0)
  }
}, {
  immediate: true
})

const onTurn = () => {
  $('#flipbook').turn({
    autoCenter: true, //自动居中, 默认false
    height: pageHeight.value, //高度
    width: pageWidth.value * 2, //宽度
    display: 'double', //单页显示/双页显示  single/double
    elevation: 50,
    duration: 500, //翻页速度(毫秒), 默认600ms
    gradients: true, //翻页时的阴影渐变, 默认true
    acceleration: true, //硬件加速, 默认true, 如果是触摸设备设置为true
    page: 1, //设置当前显示第几页
    pages: pageCav.value, //总页数
    turnCorners: 'bl,br,tl,tr,l,r', // 设置可翻页的页角
    when: {
      //监听事件
      turning: async function (e, page, view) {
        console.log(e, page, view)

        // 翻页前触发
      },
      turned: function (e, page) {
        console.log(e, page, pageCav.value)
        currentPage.value = page
        // 翻页后触发
      },
    },
  })
}

// 只显示一个弹窗 （index：网页底部的图标次序）
const show = (index) => {
  console.log(index)
  console.log(drawerList.value[index])
  drawerList.value.forEach((item, ind) => {
    if (index === ind) {
      item.isShow = true
    } else {
      item.isShow = false
    }
  })
  console.log(drawerList.value[index])
}

// 关闭弹窗
const close = (index) => {
  drawerList.value[index].isShow = false
  console.log(drawerList.value[index])
}


// *** 中间按钮 ***
// 上一页
const goToPrevPage = () => {
  $('#flipbook').turn('previous');
}
// 下一页
const goToNextPage = () => {
  $('#flipbook').turn('next');
}
// 跳到某一页
const goToPage = (pageNumber) => {
  $('#flipbook').turn('page', pageNumber);
  currentPage.value = pageNumber;
}

// *** 右侧按钮 ***
// 自动翻页
let timer = ref(null)
const showLoop = () => {
  console.log(isLoop.value)
  if (!isLoop.value) {
    show(10)
  }
  else {
    ElMessage({
      message: '已关闭自动翻页',
      type: 'warning',
      offset: 100
    })
    if (timer.value) {
      clearInterval(timer.value)
    }
    isLoop.value = false
  }
}
const closeLoop = (num) => {
  if (num) {
    console.log(num.value)
    ElMessage({
      message: '已开启自动翻页',
      type: 'success',
      offset: 100
    })
    isLoop.value = true
    timer.value = setInterval(() => {
      goToNextPage()
    }, num.value * 1000)
  }
  close(10)
}
// 图书放大
const showBig = () => {
  $('#flipbook').turn('zoom', scaleValue.value += 0.1);
}
// 图书缩小
const showSmall = () => {
  $('#flipbook').turn('zoom', scaleValue.value -= 0.1);
}
// 背景音乐
const showSong = () => {
  if (!isSong.value) { // 播放
    playMusic()
  }
  else { // 关闭
    stopMusic()
  }
}

const audioAutoPlay = () => {
  let audio = document.getElementById("audio")
  audio.play()
  document.removeEventListener("touchstart", audioAutoPlay);
}
const musicNow = () => {
  // 播放音乐
  let oAudio = document.querySelector("#audio")
  oAudio.onended = function () {
    //播放完毕，重新循环播放
    oAudio.load()
    oAudio.play()
  }
  audioAutoPlay()
}
// 停止播放音乐
const stopMusic = () => {
  let oAudio = document.querySelector("#audio")
  oAudio.pause()
  isSong.value = false
  console.log("停止播放")
}
// 继续播放音乐
const playMusic = () => {
  musicNow()
  console.log("继续播放")
  isSong.value = true
}

// 全屏
const showFull = () => {
  toggle()
}
const {
  //是否全屏状态
  isFullscreen,
  //切换全屏
  toggle
} = useFullscreen()

// 概要、短句
const showAbstract = () => {
  sentenceShow.value = false
  abstractShow.value = true
  menuActive.value = 'option2'
}
const closeAll = () => {
  abstractShow.value = false
  sentenceShow.value = false
  menuActive.value = 'option1'
}
const showSentence = () => {
  abstractShow.value = false
  sentenceShow.value = true
  menuActive.value = 'option3'
}

// 右键菜单
const selectedText = ref('')
const showContextMenu = (event) => {
  selectedText.value = window.getSelection().toString().trim();
  if (selectedText.value.trim()) {
    $('#customContextMenu').css('top', (event.clientY + 10) + 'px')
    $('#customContextMenu').css('left', event.clientX + 'px')
    $('#customContextMenu').show()
    $('.menu-mask').show()
  } else {
    $('#customContextMenu').hide()
    $('.menu-mask').hide()
  }
}


const hideMenu = () => {
  $('#customContextMenu').hide()
  $('.menu-mask').hide()
}

const ShowMenu = async (index) => {
  $('.loading').show()
  $('#customContextMenu').hide()
  $('.menu-mask').hide()
  // 知识理解
  if (index === 1) {
    const { data } = await getNotionService(selectedText.value)
    $('.loading').hide()
    menubox.value.open(index, data.content)
  } else if (index === 2) {
    const { data } = await getNewServer(selectedText.value)
    $('.loading').hide()
    menubox.value.open(index, data.content)
  } else {
    const { data } = await getSceneServer(selectedText.value)
    $('.loading').hide()
    menubox.value.open(index, data.image)
  }
  menuShow.value = true


}
const CloseMenu = () => {
  menuShow.value = false
}

const toSearch = () => {
  searchValue.value = selectedText.value
  show(2)
  $('#customContextMenu').hide()
  $('.menu-mask').hide()
}

const menubox = ref(null)

</script>

<template>
  <HeaderView @Allclose="closeAll" @abstractShow="showAbstract" @sentenceShow="showSentence" :menu-active="menuActive">
  </HeaderView>
  <!-- 书本 -->
  <div class="box">
    <div class="content" @contextmenu.prevent="showContextMenu" ref="content">
      <div id="flipbook"></div>
    </div>
    <audio ref="audio" id="audio" :src="require('@/assets/bgsong1.mp3')"></audio>
  </div>

  <!-- 上一页下一页 -->
  <div class="prev" @click="goToPrevPage">
    <el-icon>
      <Back />
    </el-icon>
  </div>
  <div class="next" @click="goToNextPage">
    <el-icon>
      <Right />
    </el-icon>
  </div>
  <!-- 网页底部 -->
  <FooterView @toNext="goToNextPage" @toPage="goToPage" :page="currentPage" @toPrev="goToPrevPage" :pages="pageCav"
    @directoryShow='show(0)' @previewShow="show(1)" @serKnowShow="show(2)" @atlasShow="show(3)" @extendShow="show(4)"
    @listenShow="show(5)" @noteShow="show(6)" @talkShow="show(7)" @likeShow="show(8)" @starShow="show(9)"
    @loopShow="showLoop" @settingShow="show(11)" @feedbackShow="show(12)" @helpShow="show(13)" @aboutShow="show(14)"
    @showFull="showFull" :isFull="isFullscreen" :isLoop="isLoop" @SmallShow="showSmall" @BigShow="showBig"
    @SongShow="showSong" :isSong="isSong">
  </FooterView>

  <!-- loading -->
  <div class="loading">
    <div class="img">
      <img src="../assets/loading.gif">
    </div>
  </div>

  <!-- 概要 -->
  <AbstractPage :show="abstractShow" @toPage="goToPage" @close="closeAll"></AbstractPage>
  <!-- 短句 -->
  <SentencePage :show="sentenceShow" @toPage="goToPage" @close="closeAll"></SentencePage>
  <!-- 右击菜单弹窗 -->
  <MenuBox ref="menubox" :show="menuShow" @close="CloseMenu"></MenuBox>

  <!-- *** 左侧弹窗 *** -->
  <!-- 目录 -->
  <DirectoryDrawer :show="drawerList[0].isShow" @close="close(0)" @toPage="goToPage"></DirectoryDrawer>
  <!-- 知识预览 -->
  <PreviewDrawer :show="drawerList[1].isShow" @close="close(1)"></PreviewDrawer>
  <!-- 知识搜索 -->
  <SerKnowDrawer :value="searchValue" :show="drawerList[2].isShow" @toPage="goToPage" @close="close(2)"></SerKnowDrawer>
  <!-- 知识图谱 -->
  <AtlasDrawer :show="drawerList[3].isShow" @close="close(3)"></AtlasDrawer>
  <!-- 知识扩展 -->
  <ExtendDrawer :show="drawerList[4].isShow" @close="close(4)"></ExtendDrawer>
  <!-- 听书 -->
  <ListenDrawer :show="drawerList[5].isShow" @close="close(5)"></ListenDrawer>
  <!-- 注释 -->
  <NoteDrawer :show="drawerList[6].isShow" @close="close(6)"></NoteDrawer>
  <!-- 知识交流 -->
  <TalkDrawer :show="drawerList[7].isShow" @close="close(7)"></TalkDrawer>
  <!-- 点赞列表 -->
  <LikeDrawer :show="drawerList[8].isShow" @close="close(8)"></LikeDrawer>
  <!-- 收藏列表 -->
  <StarDrawer :show="drawerList[9].isShow" @close="close(9)"></StarDrawer>

  <!-- *** 右侧弹窗 *** -->
  <!-- 自动翻页 -->
  <LoopDrawer :show="drawerList[10].isShow" @close="closeLoop"></LoopDrawer>
  <!-- 设置 -->
  <SettingDrawer :show="drawerList[11].isShow" @close="close(11)"></SettingDrawer>
  <!-- 反馈 -->
  <FeedbackDrawer :show="drawerList[12].isShow" @close="close(12)"></FeedbackDrawer>
  <!-- 帮助 -->
  <HelpDrawer :show="drawerList[13].isShow" @close="close(13)"></HelpDrawer>
  <!-- 关于 -->
  <AboutDrawer :show="drawerList[14].isShow" @close="close(14)"></AboutDrawer>

  <div id="customContextMenu" @click.stop="handleMenuClick">
    <div class="menu-item" @click="ShowMenu(1)">
      <div><el-icon>
          <DataLine />
        </el-icon>知识理解</div>
      <el-icon>
        <ArrowRight />
      </el-icon>
    </div>
    <div class="menu-item"  @click="ShowMenu(2)">
      <div><el-icon>
          <Guide />
        </el-icon>新内容生成</div>
      <el-icon>
        <ArrowRight />
      </el-icon>
    </div>

    <div class="menu-item"  @click="ShowMenu(3)">
      <div><el-icon>
          <Picture />
        </el-icon>场景生成</div>
      <el-icon>
        <ArrowRight />
      </el-icon>
    </div>
  </div>
  <div class="menu-mask" @click="hideMenu"></div>
</template>
 
<style scoped lang="less">
@import url('../../node_modules/pdfjs-dist/web/pdf_viewer.css');

.menu-mask {
  display: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 250;
  left: 0;
  top: 0;
  background-color: transparent;
}

.loading {
  display: none;
  width: 100vw;
  height: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #c9c7c742;
}

.loading .img {
  width: 250px;
  height: 250px;
  margin: 35vh auto;
}

.prev {
  left: 5%;
}

.next {
  right: 5%;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(143, 145, 144, 0.226);
  border-radius: 10px;
}

.prev:hover .el-icon,
.next:hover .el-icon {
  color: #ea4926;
}

.prev:hover,
.next:hover {
  background-color: #ea4a261f;
}

.prev .el-icon,
.next .el-icon {
  font-size: 50px;
  padding: 10px;
  color: #666;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url('@/assets/bg4.png');
  background-size: cover;
}

.box .content {
  display: flex;
}

#flipbook {
  transition: margin-left 1s;
  -webkit-transition: margin-left 1s;
  -moz-transition: margin-left 1s;
  -o-transition: margin-left 1s;
  -ms-transition: margin-left 1s;
  transition: margin-left 1s;
}

#customContextMenu {
  width: 130px;
  z-index: 300;
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  .menu-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    justify-content: space-between;
    cursor: pointer;

    div{
      display: flex;
      align-items: center;
      .el-icon{
        font-size: 18px;
        margin-right: 5px;
      }
    }

    &:hover {
      background-color: #ea4a262f;
      color: #ea4926;
    }
  }
}

.menu .tex {
  background-color: #ea4a2613;
  padding: 20px;
  border-radius: 10px;
}</style>