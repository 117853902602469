<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}
const props = defineProps({
    show: Boolean
})

</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            关于
        </template>
        <template #content>
            <div class="content">
                <div>汕头大学出版社语义电子书系统</div>
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style scoped lang="less">
.content {
    display: flex;
    height: 70%;
    div{
        margin: auto;
        font-size: 16px;
    }
}
</style>