<script setup>
import DrawerLayoutVue from './DrawerLayout.vue';
const props = defineProps({
    show: Boolean
})
const emit = defineEmits(['close'])
const close = () => {
    emit('close')
}
</script>
<template>
    <DrawerLayoutVue @close="close" :show="show">
        <template #title>
            听书
        </template>
        <template #content>
            <div class="content">
                
            </div>
        </template>
    </DrawerLayoutVue>
</template>
<style lang="less" scoped>

</style>