<script setup>
import { onMounted, ref} from 'vue';
import { getDirectoryService } from '@/api/directory';
import { useRoute, useRouter } from 'vue-router';
import { CircleClose, DArrowLeft } from '@element-plus/icons-vue'

const props = defineProps({
    show: Boolean
})
const emit = defineEmits(['close','toPage'])
const close = () => {
    emit('close')
}

const route = useRoute()
const router = useRouter()

const driList = ref([])
// 二级默认选中
// const defaultActive = ref('/book/' + route.params.id || '/book/1')

const transformData = (inputData) => {
    // 创建一个映射表，以catalogid为键
    const catalogidMap = new Map(inputData.map(item => [item.catalogid, { ...item, children: [] }]));
    // 遍历原始数据，将每个节点添加到其父节点的children数组中
    for (const item of inputData) {
        const parentNode = catalogidMap.get(item.parentid);
        if (parentNode) {
            parentNode.children.push(catalogidMap.get(item.catalogid));
        }
    }
    // 找到根节点并返回
    return Array.from(catalogidMap.values()).filter(item => item.parentid === 0);
}

onMounted(async () => {
    const { data: { list } } = await getDirectoryService()
    driList.value = transformData(list);
    console.log(driList.value)
})

// watch(() => route.params.id, () => {
//     // 二级菜单设置默认打开
//     defaultActive.value = '/book/' + route.params.id
// })

const savePath = (item) => {
    localStorage.setItem('pdf-path', item.catalogid)
    console.log(item)
    emit('toPage',item.pdfpage)

}


</script>
<template>
    <div class="drawer" :class="{ 'drawer-open': show }">
        <div class="top1"></div>
        <div class="content">
            <div class="aside-nav">
                <div class="top" v-if="driList != []">
                    <div></div>
                    <span class="name">目录</span>
                    <div>
                    </div>
                </div>
                <!-- 目录 -->
                <el-row class="tac list">
                    <el-col>
                        <el-menu unique-opened>
                            <!-- <el-menu :default-active="defaultActive" router unique-opened> -->
                            <template v-for="item in driList" :key="item.catalogid">
                                <template v-if="item.children.length > 0">
                                    <el-sub-menu :index="item.catalogid">
                                        <template #title>
                                            <span>{{ item.name }}</span>
                                        </template>
                                        <el-menu-item-group class="group" v-for="(it) in item.children" :key="it.catalogid">
                                            <el-menu-item @click="savePath(it)" class="book-title"
                                                :index="it.catalogid">
                                                {{ it.name }}
                                            </el-menu-item>
                                            <el-menu-item @click="savePath(i)" class="book-item"
                                                :index="i.catalogid" v-for="i in it.children" :key="i.id">{{
                                                    i.name
                                                }}</el-menu-item>
                                        </el-menu-item-group>
                                    </el-sub-menu>
                                </template>
                                <template v-else>
                                    <el-menu-item class="menu-one" :index="item.catalogid"
                                        @click="savePath(item)">
                                        <span>{{ item.name }}</span>
                                    </el-menu-item>
                                </template>
                            </template>

                        </el-menu>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="bottom"></div>
        <div class="close" @click="close">
            <el-icon>
                <DArrowLeft />
            </el-icon>
        </div>
    </div>
</template>
<style scoped lang="less">
.drawer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 25%;
    max-width: 400px;
    height: 100%;
    top: 0;
    left: -100%;
    margin-bottom: 50px;
    transition: left 0.5s linear;

    .top1 {
        width: 100%;
        height: 60px;
        background-color: #00000000;
    }

    .bottom {
        width: 100%;
        height: 50px;
        background-color: #00000000;
    }

    .content {
        width: 100%;
        background-color: #fff;
        padding: 20px;
        flex: 1;
        box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, .08), 0px 12px 32px rgba(0, 0, 0, .12), 0px 8px 16px -8px rgba(0, 0, 0, .16);
        ;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .close {
        position: absolute;
        top: 45%;
        left: 100%;
        padding: 30px 5px;
        background-color: rgba(143, 145, 144, 0.226);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        &:hover .el-icon {
            color: #ea4926;
        }

        &:hover {
            background-color: #ea4a261c;
        }

        .el-icon {
            font-size: 20px;
            color: #666;
        }
    }
}

.drawer-open {
    left: 0;
}

.aside-nav {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        display: none;
    }

    .top {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #e4e3e3;
        margin-bottom: 20px;

        .name {
            display: block;
            margin-right: 10px;
            font-size: 20px;
            text-align: center;
            font-weight: bold;
            color: #ea4926;
        }

        .el-icon {
            font-size: 22px;
            color: #ea4926;
        }
    }

}

::v-deep .menu-one.el-menu-item,
::v-deep .el-sub-menu__title {
    background-color: rgba(235, 233, 233, 0.685);
    font-size: 16px;
}

.book-title {
    display: flex;
    font-size: 16px;
    color: #333;
    align-items: flex-start;
}

::v-deep .el-menu-item.is-active,
.book-title:active {
    color: #ea4926;
}

.el-sub-menu ::v-deep .book-title.is-active {
    color: #ea4926;
}

.book-title .li-name {
    color: #ea4926;
}


.el-sub-menu .book-item:hover {
    background-color: #fff;
    color: #ea4926;
}

.el-sub-menu .book-title:hover {
    color: #ea4926;
}

::v-deep .el-sub-menu.is-opened .el-sub-menu__title {
    color: #ea4926;
}

.group span a:active {
    color: #ea4926;
}

::v-deep .el-menu {
    border-left: solid 1px var(--el-menu-border-color);
}

.el-sub-menu ::v-deep .el-menu-item {
    height: auto;
    line-height: 1.5;
    font-size: 15px;
}


::v-deep .el-menu--vertical:not(.el-menu--collapse):not(.el-menu--popup-container) .el-menu-item {
    white-space: wrap;
}

::v-deep .book-title {
    padding: 5px 20px !important;
    background-color: #ea4a2625;
    border-radius: 10px;
    margin: 20px;
}

.group {
    margin-bottom: 30px;
}

.el-sub-menu ::v-deep .el-menu-item-group__title {
    display: none;
}

.el-sub-menu .book-item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
}

.el-sub-menu .book-item:hover::before {
    background-color: #ea4926;
}

.el-sub-menu .book-item::before {
    position: absolute;
    left: 23px;
    top: 7px;
    display: block;
    flex-shrink: 0;
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 50%;
    background-color: rgba(88, 87, 87, 0.406);
    margin-right: 10px;
}

.el-sub-menu .book-item.is-active {
    color: #ea4926;
}

.el-sub-menu .is-active::before {
    background-color: #ea4926;
}</style>