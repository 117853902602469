<script setup>
import { Back, Right, ArrowRight } from '@element-plus/icons-vue';
import { ref, onMounted } from 'vue'
import { getPrecisServer } from '@/api/abstract.js'

const showIndex = ref(0)
const id = ref(0)
const props = defineProps({
    show: Boolean
})
const emit = defineEmits('toPage','close')
const dataList = ref(null)
const prev = () => {
    getList(dataList.value.prev)
}
const next = () => {
    getList(dataList.value.next)
}
const getList = async (num) => {
    const { data } = await getPrecisServer(num)
    if (data.list.length <= 0) {
        id.value++
        getList(id.value)
    } else {
        dataList.value = data.list
    }
}
const toPage = (page) =>{
    emit('toPage',page)
    // 关闭当前弹窗
    emit('close')
}
onMounted(async () => {
    getList(id.value)
})
</script>

<template>
    <div class="box2" v-if="show">
        <div class="con" v-if="dataList">

            <div class="title">{{ dataList.section }}</div>
            <div class="content">
                <p>{{ dataList.sentence }}</p>
                <div class="end">
                    <div class="zhang" v-if="dataList.chapter">-- {{ dataList.chapter }} --</div>
                    <div class="to" @click="toPage(dataList.pdfpage)">
                        返回原文<el-icon>
                            <ArrowRight />
                        </el-icon>
                    </div>

                </div>
            </div>

        </div>

        <div class="prev" @click="prev">
            <el-icon>
                <Back />
            </el-icon>
        </div>
        <div class="next" @click="next">
            <el-icon>
                <Right />
            </el-icon>
        </div>
    </div>
</template>
<style scoped lang="less">
.box2 {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 60;
    background-image: url('@/assets/bg4.png');
    background-size: cover;

    .prev {
        left: 5%;
    }

    .next {
        right: 5%;
    }

    .prev,
    .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(143, 145, 144, 0.226);
        border-radius: 10px;

        .el-icon {
            font-size: 50px;
            padding: 10px;
            color: #666;
        }

        &:hover {
            background-color: #ea4a261f;
        }

        &:hover .el-icon {
            color: #ea4926;
        }
    }

    .con {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        width: 50%;
        height: 70vh;
        padding: 20px;
        transform: translate(-50%, -50%);
        border: 1px solid #9c9c9c77;
        overflow-y: scroll;
        box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08);

        &::-webkit-scrollbar {
            display: none;
        }

        .title {
            font-size: 20px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0 20px;
            border-bottom: 1px dashed #66666541;

            &::after {
                content: '';
                width: 40px;
                height: 3px;
                background-color: #ea4926;
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }

        .content {
            height: 50vh;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            p {
                font-size: 15px;
                line-height: 2;
                margin-bottom: 10px;
                text-indent: 2em;
            }

            .end {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .zhang{
                    font-size: 14px;
                    color: #666;
                }
                .to {
                    position: absolute;
                    right: 0;

                    cursor: pointer;
                    margin-left: auto;
                    color: #ea4926;
                    display: flex;
                    align-items: center;
                    margin-right: 20px;

                    .el-icon {
                        margin-left: 5px;
                    }
                }

            }
        }
    }
}</style>