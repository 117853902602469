import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores'

const router = createRouter({
    // 5、配置路由
    //5.1配置路模式
    history:createWebHistory(),
    // 5.2 配置路由对象数组
    routes: [
        { path: '/login', component: () => import('@/views/LoginPage.vue') }, // 登录页
        {
        path:'/',component:() => import('@/views/MainPage.vue')
        }
    ]
})
router.beforeEach((to) => {
    const useStore = useUserStore()
    if (!useStore.token && to.path !== '/login') return '/login'
  })

// 6、导出路由对象
export default router;