<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import { userLogoutService } from '@/api/user.js'
import { useUserStore } from '@/stores'
import { ElMessageBox, ElMessage } from 'element-plus'


const router = useRouter()
const value = ref('option1')
const userStore = useUserStore()

const emit = defineEmits(['abstractShow', 'sentenceShow', 'Allclose'])
const props = defineProps({
    menuActive: String
})

const options = [
    {
        value: 'option1',
        label: '原文',
    },
    {
        value: 'option2',
        label: '摘要',
    },
    {
        value: 'option3',
        label: '要点',
    },
]



const change = (e) => {
    if (e === 'option1') {
        emit('Allclose')
    } else if (e === 'option2') {
        emit('abstractShow')
    } else {
        emit('sentenceShow')
    }
}
const handleCommand = async (key) => {
    if (key === 'logout') {
        ElMessageBox.confirm(
            '你确认要进行退出么',
            '温馨提示',
            {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
            }
        )
            .then(async() => {
                // 清除本地的数据 (token + user信息)
                await userLogoutService()
                router.push('/login')
                userStore.removeToken()
                ElMessage({
                    type: 'success',
                    message: '你已退出登录',
                })
            })
            .catch(() => {
                return
            })

        // ElMessageBox.alert('This is a message', 'Title', {
        //     // if you want to disable its autofocus
        //     // autofocus: false,
        //     confirmButtonText: 'OK',
        //     closeOnClickModal: true,
        //     callback: async (action) => {
        //         console.log(action)
        //         // 清除本地的数据 (token + user信息)
        //         // await userLogoutService()
        //         // router.push('/login')
        //         // userStore.removeToken()
        //         // ElMessage({
        //         //     type: 'success',
        //         //     message: '退出登录',
        //         // })
        //     },
        // })
    }
}

</script>
<template>
    <div class="header">
        <div class="title">
            <div class="name">
                <i class="iconfont icon-shuben"></i>
                <span>栽培式习作教学生态模式</span>
            </div>
            <el-select @change="change" :model-value="menuActive" class="m-2" placeholder="原文">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
        </div>
        <div class="info">
            <el-dropdown placement="bottom-end" @command="handleCommand">
                <span class="el-dropdown__box">
                    <img src="@/assets/user-img.png" />
                    <el-icon>
                        <CaretBottom />
                    </el-icon>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="logout" :icon="SwitchButton">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>

    </div>
</template>
<style scoped lang="less">
.header {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 70;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: rgba(143, 145, 144, 0.527);

    .info {
        img {
            width: 34px;
        }
    }
}

.title {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.title .name {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
    flex-shrink: 0;
}

.title .name .iconfont {
    font-size: 30px;
    color: #ea4926;
    font-weight: normal;
    margin-right: 10px;
}

.title ::v-deep .el-input__wrapper {
    width: 80px;
    background-color: rgba(0, 0, 0, 0);
}

.title ::v-deep .el-select {
    --el-select-input-focus-border-color: #fff;
}

.title ::v-deep .el-select .el-input .el-select__caret {
    color: #7c7c7c;
}

.title ::v-deep .el-select .el-input__inner::placeholder {
    color: #333;
}

.title ::v-deep .el-select-dropdown__item.selected {
    color: #ea4926 !important;
}

.search {
    display: flex;
    border: 1px solid #fff;
    border-radius: 5px;
}

.search input {
    padding: 1px 5px;
    background-color: #00000000;
}
</style>