import request from '@/utils/request'

// 知识理解
export const getNotionService = (txt) => {
    return request.post('/generate/knowledge',{txt})
}

// 新内容生成
export const getNewServer = (txt) =>{
    return request.post('/generate/newcontent',{txt})
}

// 场景生成
export const getSceneServer = (txt) =>{
    return request.post('/generate/scene',{txt})
}
