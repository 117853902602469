<script setup>
import { ref } from 'vue'
const props = defineProps({
  show: Boolean
})
const emit = defineEmits(['close'])
const menuTitle = ref('')
const menuType = ref(0)
const tex = ref('')
const open = (type, str) => {
  console.log(type + str)
  tex.value = str
  if (type === 1) {
    menuType.value = 1
    menuTitle.value = '知识理解'
  } else if (type === 2) {
    menuType.value = 2
    menuTitle.value = '新内容生成'
  } else {
    menuType.value = 3
    menuTitle.value = '场景生成'
  }
}

const close = () =>{
  emit('close')
}

defineExpose({
  open
})
</script>

<template>
  <el-dialog @close="close" class="menu" :title="menuTitle" :model-value="show" width="40%" destroy-on-close align-center>
    <div class="tex" v-if="menuType!=3">{{tex}}</div>
    <div class="img" v-else>
      <img :src="tex">
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" color="#ea4926" @click="close">
          确认
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<style scoped>
.img{
  width: 70%;
  margin: 0 auto;
}
</style>